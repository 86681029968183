import {
  Badge,
  BadgeShape,
  BadgeType,
  Button,
  Col,
  DataGrid,
  DataGridColumnType,
  Flex,
  H1,
  H3,
  Row,
} from '@appliedsystems/applied-design-system';
import { CreateQuoteSuccessResponse, premiumFinanceProviderLabels, toIntlFormat } from '@appliedsystems/payments-core';
import React, { useMemo } from 'react';
import { currencyMap } from '../../../src/constants/constants';
import { usePaymentsTranslation } from '../../../src/hooks/usePaymentsTranslation';
import { Locale } from '../../../src/store/Locale';
import { toDateStringNoTz } from '../../../src/util/date';
import { toIntlFormatHelper } from '../../../src/util/getAmountWithFees';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { Layout } from '../Layout/Layout';
import { PaymentSummary } from '../SummaryCard/SummaryCard';
import greenCheck from './check-circle.svg';
import classes from './PremiumFinanceConfirmationPage.module.scss';

type Props = {
  pfQuote: CreateQuoteSuccessResponse;
  signedBase64Pfa: string;
};

export const PremiumFinanceConfirmationPage = ({ pfQuote, signedBase64Pfa }: Props) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const { hppData } = useHppDataStore();
  const currencyCode = currencyMap[locale];

  const columns = useMemo<DataGridColumnType<CreateQuoteSuccessResponse>[]>(() => {
    return [
      // { name: 'policy', title: 'Policy' },
      {
        name: 'amountFinanced',
        title: t('AMOUNT_FINANCED'),
        cellRenderer: () => (
          <span>
            {toIntlFormat(
              {
                amount: pfQuote.amountFinanced,
                currencyCode,
              },
              locale,
            )}
          </span>
        ),
      },
      {
        name: 'downPayment',
        title: t('DOWN_PAYMENT'),
        cellRenderer: () => {
          return (
            <Flex className="flex-no-wrap flex-valign-center gap-h-50">
              <span>
                {toIntlFormat(
                  {
                    amount: pfQuote.downPaymentAmount,
                    currencyCode,
                  },
                  locale,
                )}
              </span>
              <Badge shape={BadgeShape.Pill} type={BadgeType.Success} size="xxs" icon={'NoIcon'}>
                {t('PAID')}
              </Badge>
            </Flex>
          );
        },
      },
      {
        name: 'retainedPayments',
        title: t('RETAINED_PAYMENTS'),
        isVisible: pfQuote.retainedPayments > 0,
        cellRenderer: () => {
          return (
            <Flex className="flex-no-wrap flex-valign-center gap-h-50">
              <span>
                {toIntlFormat(
                  {
                    amount: pfQuote.retainedPayments,
                    currencyCode,
                  },
                  locale,
                )}
              </span>
              <Badge shape={BadgeShape.Pill} type={BadgeType.Success} size="xxs" icon={'NoIcon'}>
                {t('PAID')}
              </Badge>
            </Flex>
          );
        },
      },
      {
        name: 'monthlyAmount',
        title: t('MONTHLY_AMOUNT'),
        cellRenderer: () => (
          <span>
            {toIntlFormat(
              {
                amount: pfQuote.installmentAmount,
                currencyCode,
              },
              locale,
            )}
          </span>
        ),
      },
      {
        name: 'numberOfPayments',
        title: t('NUMBER_OF_PAYMENTS'),
        cellRenderer: () => <span>{pfQuote.numberOfInstallments}</span>,
      },
      {
        name: 'nextPaymentDue',
        title: t('NEXT_PAYMENT_DUE'),
        cellRenderer: () => <span>{toDateStringNoTz(pfQuote.firstDueDate)}</span>,
      },
    ];
  }, [locale, t, currencyCode, pfQuote]);

  const printConfirmation = () => {
    // Save the original title and set the new title to the merchant name for saving pdf in merchant name
    const originalTitle = document.title;
    document.title = t('PRINT_CONFIRMATION');
    window.print();
    document.title = originalTitle;
  };

  const downloadPfa = async () => {
    // Decode the Base64 string
    const byteCharacters = atob(signedBase64Pfa);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob object from the byte array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = `${pfQuote.company}-${pfQuote.quoteId}`;

    // Append the link to the body (not visible) and click it
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);

    // Release the object URL
    URL.revokeObjectURL(url);
  };

  const paymentAmountDetail = useMemo(() => {
    const currencyCode = currencyMap[locale];
    return {
      fee: toIntlFormatHelper(hppData.paymentFee, currencyCode, locale),
      subtotal: toIntlFormatHelper(hppData.paymentAmount, currencyCode, locale),
      total: toIntlFormatHelper(hppData.paymentTotal, currencyCode, locale),
    };
  }, [hppData.paymentAmount, hppData.paymentFee, hppData.paymentTotal, locale]);

  const rightContainer = (
    <div className={classes.premiumFinanceConfirmation}>
      <PaymentSummary shouldDisplayDescription={true} paymentAmountDetail={paymentAmountDetail} isConfirmation={true} />
    </div>
  );

  const leftContainer = (
    <Flex>
      <div className={classes.financeInfoContainer}>
        <div className={classes.financingSummary}>
          <div className={classes.financingSummaryText}>
            <H3>{t('FINANCING_SUMMARY')}</H3>
          </div>
          <hr className={classes.line} />
        </div>
        <p className="text-left">{t('PF_PAYMENT_AGREEMENT_TERMS')}</p>
        <div className={classes.financingContainer}>
          <div className={classes.financingItem}>
            <h5>{t('FINANCE_COMPANY')}</h5>
            <p>{premiumFinanceProviderLabels[pfQuote.company]}</p>
          </div>
          <div className={classes.financingItem}>
            <h5>{t('FINANCE_QUOTE_NUMBER')}</h5>
            <p>{pfQuote.providerQuoteId}</p>
          </div>
        </div>
        <div className={classes.dataGridContainer}>
          <Row className="mb-100">
            <Col xs={12}>
              <DataGrid<CreateQuoteSuccessResponse>
                columns={columns}
                rows={[pfQuote]}
                rowIdField="id"
                testId="premium-finance-details"
                hideFooter
              />
            </Col>
          </Row>
        </div>
        <div className={classes.buttonContainer}>
          <Button type="secondary" onClick={downloadPfa}>
            {t('DOWNLOAD_PF_AGREEMENT')}
          </Button>
          <Button type="secondary" onClick={printConfirmation}>
            {t('PRINT_CONFIRMATION')}
          </Button>
        </div>
      </div>
    </Flex>
  );

  return (
    <div>
      <Flex>
        <Row>
          <Col xs={12}>
            <div className={classes.successIcon}>
              <img src={greenCheck} alt={t('CONFIRMATION_SUCCESS_ALT_TEXT')} />
            </div>
            <Flex className="flex-d-column">
              <H1 className="text-center">
                {t('PF_PAYMENT_CONFIRMATION', undefined, {
                  paidTotal: toIntlFormatHelper(hppData.paymentTotal, currencyMap[locale], locale),
                } as any)}
              </H1>
              <div className={classes.confirmationText}>
                <p className="text-center">
                  {t('PF_PAYMENT_CONFIRMATION_DESCRIPTION', undefined, {
                    email: hppData.userEmail,
                  } as any)}
                </p>
                <p className="text-center">{t('BELOW_FINANCE_SUMMARY')}</p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Flex>
      <Layout
        leftContainer={leftContainer}
        rightContainer={rightContainer}
        confirmationComponent={<></>}
        showConfirmationComponent={false}
        showHeader={false}
      ></Layout>
    </div>
  );
};
