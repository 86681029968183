import { Flex } from '@appliedsystems/applied-design-system';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import classes from './Copyright.module.scss';

export const Copyright = () => {
  const { t } = usePaymentsTranslation();
  const { data: agencyDetails, hppConfig } = useAgencyDetailsStore();
  return (
    <Flex className={classes.copyright}>
      {hppConfig.showCopyright && (
        <sub>
          {t('COPYRIGHT')} &copy; {agencyDetails?.name} {new Date().getFullYear()}
        </sub>
      )}
    </Flex>
  );
};
