import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError';
import {
  ADSAnyObject,
  ADSChangeEvent,
  Button,
  Col,
  Combobox,
  Container,
  DataGrid,
  Flex,
  Form,
  H1,
  ListItem,
  object,
  RadioGroup,
  Row,
  SimpleList,
  string,
} from '@appliedsystems/applied-design-system';
import { CurrencyCode, ErrorCode, LocaleCode, PaymentAttribute, toIntlFormat } from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import {
  useDisableStoredPaymentMethod,
  useDisableStoredPaymentMethodLastUpdatedDate,
} from '../../hooks/useDisableStoredPaymentMethod';
import { useFeatureFlags } from '../../hooks/useFeatureFlag';
import { useIsEligibleForFlowV2 } from '../../hooks/useIsEligibleforHppCheckoutUi';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useSecretCode } from '../../hooks/useSecretCode';
import { useSessionToken } from '../../hooks/useSessionToken';
import { getLocale, textToLocaleKey, Translation } from '../../localization/translations';
import { useCheckoutReducer } from '../../reducers/useCheckoutReducer';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { Locale } from '../../store/Locale';
import { invertColor } from '../../util/util';
import { AccountManagementModals } from '../AccountManagementModals';
import { AdyenPaymentContainer } from '../AdyenPaymentContainer';
import { CheckboxRecaptcha } from '../CheckboxRecaptcha/CheckboxRecaptcha';
import { Loading } from '../Loading';
import { ManageAccount } from '../ManageAccount';
import { PaymentHeader } from '../PaymentHeader';
import classes from './Checkout.module.scss';
import { FormFields } from './FormFields';
import { SkeletonForm } from './SkeletonForm';

const HiddenPaymentAttributes = [PaymentAttribute.ItemNumber, PaymentAttribute.AssignedAgency];

const makeSchema = (defaultValues: any, t: (key: keyof Translation) => string) =>
  object({
    firstName: string().required(t('ERROR_FIRST_NAME_REQUIRED')).default(defaultValues.customerFirstName),
    lastName: string().required(t('ERROR_LAST_NAME_REQUIRED')).default(defaultValues.customerLastName),
    businessName: string().optional().default(defaultValues.customerBusinessName),
    email: string()
      .email(t('ERROR_EMAIL_INVALID'))
      .required(t('ERROR_EMAIL_REQUIRED'))
      .default(defaultValues.customerEmail),
  });

const errorCodeToTranslationKey: Partial<Record<ErrorCode, keyof Translation>> = {
  [ErrorCode.TooManyPspAuthorizationAttempts]: 'ERROR_TOO_MANY_PSP_AUTHORIZATION_ATTEMPTS',
  [ErrorCode.DuplicatePaymentRequest]: 'ERROR_DUPLICATE_PAYMENT',
  [ErrorCode.PaymentSessionExpired]: 'ERROR_SESSION_EXPIRED',
  [ErrorCode.PaymentAmountTooLow]: 'ERROR_FEE_AMOUNT_BELOW_MINIMUM',
  [ErrorCode.MerchantAccountNotActive]: 'ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE',
  [ErrorCode.PaymentFlowSessionIdMissing]: 'PAYMENT_FLOW_SESSION_ID_MISSING',
  [ErrorCode.PaymentFlowSessionFailedUnknown]: 'PAYMENT_FLOW_SESSION_FAILED_UNKNOWN',
  [ErrorCode.PaymentFlowSessionErrorUnknown]: 'PAYMENT_FLOW_SESSION_ERROR_UNKNOWN',
  [ErrorCode.InitAdyenCheckoutFailed]: 'INIT_ADYEN_CHECKOUT_FAILED',
  [ErrorCode.RecaptchaVerificationFailed]: 'RECAPTCHA_VERIFICATION_FAILED',
  [ErrorCode.FieldValidationFailed]: 'FIELD_VALIDATION_FAILED',
  [ErrorCode.PSPSessionUpdateFailed]: 'PSP_SESSION_UPDATE_FAILED',
};

export const Checkout = (): React.ReactElement => {
  const navigate = useNavigate();
  const { onDisableStoredPaymentMethod } = useDisableStoredPaymentMethod();
  const { sessionToken, clearSession } = useSessionToken();
  const developerMode = useSecretCode([
    'KeyI',
    'KeyA',
    'KeyM',
    'KeyA',
    'KeyD',
    'KeyE',
    'KeyV',
    'KeyE',
    'KeyL',
    'KeyO',
    'KeyP',
    'KeyE',
    'KeyR',
  ]);
  const { t } = usePaymentsTranslation();

  const { locale, setLocale, pseudoLocalization, setPseudoLocalization } = Locale.useContainer();

  const [formSchema, setFormSchema] = React.useState<ADSAnyObject>();
  const [validationHandler, setValidationHandler] =
    React.useState<(onSuccess: any, onError?: any) => () => Promise<any>>();

  const {
    isSessionLoading,
    isCheckoutLoading,
    paymentSuccess,
    hasFatalError,
    errorCode,
    setFatalError,
    sessionDetails,
    getSession,
    updateSession,
    initCheckout,
    selectedPaymentMethod,
    makePayment,
    setComponentStatus,
    updatePaymentMethod,
    errorDetail,
  } = useCheckoutReducer(sessionToken);

  useIsEligibleForFlowV2(sessionDetails?.tenantId, '/flow/checkoutv2');

  const [recaptchaAlertOpen, setRecaptchaAlertOpen] = React.useState(false);
  const [onRecaptchaSuccess, setOnRecaptchaSuccess] = useState<(recaptchaToken: string) => Promise<void>>();

  const { manageAccountWindow } = useAccountManagementStore();
  const { isAccountManagementEnabled: isAccountManagementFlagEnabled, customerUser } = useAccountManagement(
    sessionDetails?.appliedProductId,
    sessionToken,
  );
  const { isFeatureFlagsLoading } = useFeatureFlags();
  const isAccountManagementEnabled =
    isAccountManagementFlagEnabled && sessionDetails?.merchantAccountSettings.hppManageMyAccountEnabled;

  const fee: number | null = useMemo(() => {
    if (selectedPaymentMethod === 'card') {
      return sessionDetails?.feeCredit || null;
    }
    if (selectedPaymentMethod === 'ach') {
      return sessionDetails?.feeAch || null;
    }
    return null;
  }, [sessionDetails?.feeAch, sessionDetails?.feeCredit, selectedPaymentMethod]);

  useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, [customerUser?.id]);

  const { storedPaymentMethodsLastUpdatedDate } = useDisableStoredPaymentMethodLastUpdatedDate();
  useEffect(() => {
    if (storedPaymentMethodsLastUpdatedDate) {
      // If stored payment methods have been updated, reinitialize checkout
      getSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedPaymentMethodsLastUpdatedDate]);

  React.useEffect(() => {
    datadogLogs.setGlobalContextProperty('context', {
      tenant: sessionDetails?.tenantId ?? undefined,
      merchantId: sessionDetails?.merchantId ?? undefined,
      merchantAccountId: sessionDetails?.merchantAccountId ?? undefined,
      sessionId: datadogLogs.getInternalContext()?.session_id,
      amsReferenceId: sessionDetails?.referenceId ?? undefined,
      paymentFlowSessionId: sessionToken,
      pspSessionId: sessionDetails?.sessionId ?? undefined,
      customerUserId: customerUser?.id,
    });
  }, [customerUser?.id, sessionDetails, sessionToken]);

  const formDefaultValues = useMemo(() => {
    return {
      customerFirstName: sessionDetails?.customerFirstName || customerUser?.firstName || '',
      customerLastName: sessionDetails?.customerLastName || customerUser?.lastName || '',
      customerBusinessName: sessionDetails?.customerBusinessName || '',
      customerEmail: sessionDetails?.customerEmail || customerUser?.email || '',
    };
  }, [sessionDetails, customerUser]);

  useEffect(() => {
    if (!sessionDetails) {
      return;
    }

    const sessionLocale = getLocale(sessionDetails.locale);
    if (locale !== sessionLocale) {
      setLocale(sessionLocale);
    }

    setFormSchema(
      makeSchema(
        {
          ...formDefaultValues,
          locale: sessionDetails.locale ? sessionLocale : locale,
        },
        t,
      ),
    );
    // eslint-disable-next-line
  }, [sessionDetails, formDefaultValues]);

  const closeWindow = () => {
    navigate('/cancel');
  };

  const isCustomerUserAuthorizedRef = !!customerUser;

  useEffect(() => {
    if (!formSchema || !validationHandler) {
      return;
    }

    initCheckout('#dropin-container', {
      beforeSubmit: async (data, _component, actions) => {
        if (!validationHandler || typeof validationHandler !== 'function') {
          setFatalError(ErrorCode.FieldValidationFailed);
          void actions.reject();
          return;
        }
        void validationHandler?.(
          (values: any) => {
            const _data = { ...data };
            _data.paymentMethod.holderName = values.payer;
            void (async () => {
              const updated = await updateSession({
                customer: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  businessName: values.businessName,
                  email: values.email,
                },
              });
              if (updated) {
                void actions.resolve(_data);
              } else {
                void actions.reject();
              }
            })();
          },
          () => {
            void actions.reject();
          },
        )();
      },
      onPaymentCompleted: (_result: any, _component: any) => {
        // Not currently used. Keeping here as reference to old method of submitting.
        // acknowledgePayment();
      },
      onError: (error: AdyenCheckoutError, component?: DropinElement) => {
        console.error('Checkout Adyen onError called', error, component?.props?.session, sessionDetails);
        if (component) setComponentStatus('ready', component);
        return;
      },
      onSelect: (paymentMethod) => {
        updatePaymentMethod(paymentMethod);
      },
      onSubmit: (state, component: DropinElement) => {
        if (!validationHandler || typeof validationHandler !== 'function') {
          setComponentStatus('ready', component);
          return;
        }
        void validationHandler?.(
          async (values: any) => {
            if (!state.isValid) {
              setComponentStatus('ready', component);
              return;
            }

            if (state.data.paymentMethod.type === 'scheme') {
              state.data.paymentMethod.holderName = `${values.firstName} ${values.lastName}`;
            }
            const sessionUpdatedSuccessfully = await updateSession({
              customer: {
                firstName: values.firstName,
                lastName: values.lastName,
                businessName: values.businessName,
                email: values.email,
              },
            });
            if (sessionUpdatedSuccessfully) {
              setOnRecaptchaSuccess(() => async (recaptchaToken: string) => {
                setRecaptchaAlertOpen(false);
                await makePayment(state.data.paymentMethod, component, recaptchaToken, state.data.storePaymentMethod);
              });
              setRecaptchaAlertOpen(true);
            }
          },
          () => {
            setComponentStatus('ready', component);
          },
        )();
      },
      enableStoreDetails: isCustomerUserAuthorizedRef,
      onDisableStoredPaymentMethod,
    });

    // eslint-disable-next-line
  }, [formSchema, validationHandler]);

  useEffect(() => {
    if (!sessionToken) {
      const errorCode: keyof Translation = 'ERROR_ADYEN_NO_SESSION';
      navigate('/flow/error', { state: { errorCode } });
    }
  }, [sessionToken, navigate]);

  useEffect(() => {
    if (hasFatalError) {
      const translationKey =
        errorCode && Object.keys(errorCodeToTranslationKey).includes(errorCode)
          ? errorCodeToTranslationKey[errorCode]
          : 'ERROR_ADYEN_CHECKOUT';

      navigate('/flow/error', {
        state: { errorCode: translationKey, errorDetail },
      });
    }
  }, [hasFatalError, errorCode, navigate, errorDetail]);

  useEffect(() => {
    const checkoutLogMessageDisplayed = sessionStorage.getItem('checkoutLogMessageDisplayed');
    if (!checkoutLogMessageDisplayed) {
      datadogLogs.logger.info('New checkout user session started');
      sessionStorage.setItem('checkoutLogMessageDisplayed', 'true');
    }
  }, []);

  const logoUrl = sessionDetails?.merchantAccountSettings?.brand?.logoUrl || sessionDetails?.brand?.logoUrl;

  let primaryColor =
    sessionDetails?.merchantAccountSettings?.brand?.primaryColor || sessionDetails?.brand?.primaryColor || '#365ef6';
  let primaryTextColor =
    sessionDetails?.merchantAccountSettings?.brand?.primaryTextColor ||
    sessionDetails?.brand?.primaryTextColor ||
    '#ffffff';
  const border = primaryColor.toLowerCase() === '#ffffff'; // show border if bg color matches page
  if (primaryColor.toLowerCase() === primaryTextColor.toLowerCase())
    // invert text color so it can be seen
    primaryTextColor = invertColor(primaryTextColor);

  if (isFeatureFlagsLoading) return <Loading />;
  return (
    <>
      <style>{`
        .adyen-checkout__button:not(.adyen-checkout__button--link):not(.adyen-checkout__payment-method__disable-confirmation__button) {          
          background-color: ${primaryColor} !important;
          color: ${primaryTextColor} !important;
          ${border ? 'border-width: 2px;' : ''}
          ${border ? 'border-style: solid;' : ''}
        }
        .adyen-checkout__button__icon {
    ${
      sessionDetails?.merchantAccountSettings?.brand?.primaryTextColor || sessionDetails?.brand?.primaryTextColor
        ? 'display: none;'
        : ''
    }
        }
        .adyen-checkout__payment-method--selected + .adyen-checkout__payment-method, .adyen-checkout__payment-method:first-child {
          border-radius: 0;          
        }
      `}</style>
      <div style={{ overflow: manageAccountWindow.isOpen ? 'hidden' : 'auto' }} className={classes.wrapper}>
        <Container className={classes.container}>
          <Row className={classes.gapFix}>
            <Col xs={0} md={2} lg={3} />
            <Col xs={12} md={8} lg={6}>
              <div className={classes.app}>
                {isAccountManagementEnabled && <PaymentHeader paymentType="checkout" />}
                <Flex className={`${classes.container} flex-d-column gap-0`}>
                  {logoUrl && (
                    <img
                      src={logoUrl}
                      alt={sessionDetails?.merchantAccountSettings?.brand?.name || sessionDetails?.brand?.name}
                      className={classes.logo}
                    />
                  )}
                  <Flex className={`flex-d-column`}>
                    <H1 className="text-center">{t('MAKE_A_PAYMENT')}</H1>
                    <p className={`${classes.subheader} text-center`}>{t('ENTER_PAYMENT_INFO')}</p>
                  </Flex>
                  <div className={classNames(classes.form, classes.topForm)}>
                    {isSessionLoading || !formSchema ? (
                      <SkeletonForm />
                    ) : (
                      <Form schema={formSchema}>
                        <FormFields
                          defaultValues={formDefaultValues}
                          setValidationHandler={setValidationHandler}
                          readonly={paymentSuccess}
                        />
                      </Form>
                    )}
                  </div>

                  {isSessionLoading || !formSchema ? (
                    <></>
                  ) : (
                    <>
                      <div className={classes.form}>
                        <div className={classes.orderSummary}>
                          <SimpleList hideBorder>
                            <ListItem>
                              <div className={`${classes.listItem}`}>
                                <div>
                                  <b>{t('ORDER_REFERENCE_ID')}</b>
                                </div>
                                <div className={classes.ellipsis}>{sessionDetails?.referenceId}</div>
                              </div>
                            </ListItem>
                            {sessionDetails?.paymentAttributes
                              ?.filter((e) => !HiddenPaymentAttributes.includes(e.name as PaymentAttribute))
                              .map((e) => {
                                return (
                                  <ListItem key={`${e.name}`}>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t(textToLocaleKey(e.name), e.name)}</b>
                                      </div>
                                      <div>{e.value}</div>
                                    </div>
                                  </ListItem>
                                );
                              })}
                            <ListItem>
                              <div className={`${classes.listItem}`}>
                                <div>
                                  <b>{t('PAYMENT_DESCRIPTION')}</b>
                                </div>
                                <div>{sessionDetails?.paymentDescription}</div>
                              </div>
                            </ListItem>
                          </SimpleList>
                          {sessionDetails?.splitPaymentAttributes &&
                            sessionDetails?.splitPaymentAttributes.length > 0 && ( // wrong fries   tracey hjealous // please never remove this comment
                              <div className={classes.splitPaymentAttributes}>
                                <DataGrid
                                  columns={[
                                    {
                                      name: PaymentAttribute.InvoiceNumber,
                                      title: t(
                                        textToLocaleKey(PaymentAttribute.InvoiceNumber),
                                        PaymentAttribute.InvoiceNumber,
                                      ),
                                    },
                                    {
                                      name: PaymentAttribute.PolicyNumber,
                                      title: t(
                                        textToLocaleKey(PaymentAttribute.PolicyNumber),
                                        PaymentAttribute.PolicyNumber,
                                      ),
                                    },
                                    {
                                      name: PaymentAttribute.ItemNumber,
                                      title: t(
                                        textToLocaleKey(PaymentAttribute.ItemNumber),
                                        PaymentAttribute.ItemNumber,
                                      ),
                                    },
                                    {
                                      name: PaymentAttribute.PaymentAmount,
                                      title: t(
                                        textToLocaleKey(PaymentAttribute.PaymentAmount),
                                        PaymentAttribute.PaymentAmount,
                                      ),
                                      columnRenderer: () => {
                                        return (
                                          <span className={classes.numericColumn}>
                                            {t(
                                              textToLocaleKey(PaymentAttribute.PaymentAmount),
                                              PaymentAttribute.PaymentAmount,
                                            )}
                                          </span>
                                        );
                                      },
                                      cellRenderer: (data) => {
                                        return (
                                          <span className={classes.numericColumn}>
                                            {data[PaymentAttribute.PaymentAmount]
                                              ? toIntlFormat(
                                                  {
                                                    amount: +data[PaymentAttribute.PaymentAmount],
                                                    currencyCode: sessionDetails.currency as CurrencyCode,
                                                  },
                                                  sessionDetails.locale as LocaleCode,
                                                )
                                              : undefined}
                                          </span>
                                        );
                                      },
                                    },
                                  ]}
                                  rows={sessionDetails?.splitPaymentAttributes.map((attributes) =>
                                    attributes.reduce((acc, curr) => {
                                      acc[curr.name as PaymentAttribute] = curr.value;
                                      return acc;
                                    }, {} as Record<PaymentAttribute, string>),
                                  )}
                                  hideFooter
                                />
                              </div>
                            )}
                        </div>
                        <SimpleList>
                          <ListItem>
                            <div className={`${classes.listItem}`}>
                              <div>{t('SUBTOTAL')}</div>
                              <div data-test="subtotal">
                                {toIntlFormat(
                                  {
                                    amount: sessionDetails!.amount,
                                    currencyCode: sessionDetails!.currency as CurrencyCode,
                                  },
                                  locale,
                                )}
                              </div>
                            </div>
                          </ListItem>
                          <ListItem>
                            <div className={`${classes.listItem}`}>
                              <div>{t('FEE')}</div>
                              <div data-test="fee">
                                <div className={classes.feeWrapper}>
                                  {toIntlFormat(
                                    {
                                      amount: fee || 0,
                                      currencyCode: sessionDetails!.currency as CurrencyCode,
                                    },
                                    locale,
                                  )}
                                </div>
                              </div>
                            </div>
                          </ListItem>
                          <ListItem>
                            <div className={`${classes.listItem}`}>
                              <div>
                                <b>{t('YOUR_TOTAL')}</b>
                              </div>
                              <div data-test="yourTotal">
                                <b>
                                  {toIntlFormat(
                                    {
                                      amount: sessionDetails!.amount + (fee || 0),
                                      currencyCode: sessionDetails!.currency as CurrencyCode,
                                    },
                                    locale,
                                  )}
                                </b>
                              </div>
                            </div>
                          </ListItem>
                        </SimpleList>
                      </div>
                    </>
                  )}
                  <div style={{ display: isCheckoutLoading ? 'none' : 'inherit' }}>
                    <Flex>
                      <AdyenPaymentContainer
                        isAccountManagementEnabled={isAccountManagementEnabled}
                        isPaymentSuccess={paymentSuccess}
                        isUserLoggedIn={!!customerUser}
                      />
                      {!paymentSuccess && (
                        <div style={{ paddingLeft: 17, paddingRight: 17 }} className="text-right">
                          <Button type="link" onClick={() => closeWindow()}>
                            {t('CANCEL_THIS_PAYMENT')}
                          </Button>
                        </div>
                      )}
                      <p style={{ margin: 0 }} className="text-right">
                        <sub>
                          {t('CONVENIENCE_FEE_DISCLAIMER').replace(
                            '{Company_Name}',
                            sessionDetails?.merchantAccountSettings?.brand?.name || sessionDetails?.brand?.name || '',
                          )}
                        </sub>
                      </p>
                      <p style={{ marginTop: 0 }} className="text-right">
                        <sub>
                          {t('COPYRIGHT')} &copy;{' '}
                          {sessionDetails?.merchantAccountSettings?.brand?.name || sessionDetails?.brand?.name}{' '}
                          {new Date().getFullYear()}
                        </sub>
                      </p>
                    </Flex>
                    {developerMode && (
                      <>
                        <Flex>
                          <Row>
                            <Col xs={6}>
                              <RadioGroup
                                label="Pseudolocalization"
                                name="pseudolocalization"
                                value={pseudoLocalization ? 'true' : 'false'}
                                onChange={(e: ADSChangeEvent) => {
                                  setPseudoLocalization(e.target.value === 'true');
                                }}
                                items={{
                                  true: 'Enabled',
                                  false: 'Disabled',
                                }}
                              />
                            </Col>
                            <Col xs={6}>
                              <Combobox
                                name="locale"
                                label="Choose locale"
                                items={Object.values(LocaleCode)}
                                value={locale}
                                onChange={(e) => setLocale(e.target.value)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} className="text-center">
                              <Button type="link" className={classes.link} onClick={() => clearSession()}>
                                Clear Session
                              </Button>
                            </Col>
                          </Row>
                        </Flex>
                      </>
                    )}
                  </div>
                </Flex>
              </div>
              <Col xs={0} md={2} lg={3} />
            </Col>
          </Row>
        </Container>
      </div>

      {sessionToken && <AccountManagementModals paymentSessionToken={sessionToken} />}

      {isAccountManagementEnabled && sessionToken && (
        <ManageAccount
          brand={{
            name: sessionDetails?.merchantAccountSettings?.brand?.name || sessionDetails?.brand?.name,
            logoUrl,
          }}
          locale={locale}
          paymentSessionToken={sessionToken}
        />
      )}

      <CheckboxRecaptcha
        onError={setFatalError}
        onSuccess={onRecaptchaSuccess!}
        recaptchaAlertOpen={recaptchaAlertOpen}
        action="purchase"
      />
    </>
  );
};
